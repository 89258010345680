import Box, { BoxProps } from "@mui/material/Box";
import Paper, { PaperProps } from "@mui/material/Paper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { vwMobile } from "theme/muiSizes";

export const FooterContainer = styled(Paper)<PaperProps>(({ theme }) => ({
  backgroundColor: "#f0e8e8",
  padding: "50px 24px",
  position: "relative",
  borderTop: `5px solid ${theme.palette.primary.main}`,
  boxShadow: "none",
  [theme.breakpoints.down(440)]: {
    padding: `${vwMobile(50)} ${vwMobile(24)}`,
    position: "relative",
  },
}));

export const ImageCustom = styled("img")(({ theme }) => ({
  height: "120px",
  [theme.breakpoints.down(440)]: {
    height: vwMobile(120),
  },
}));

export const TitleParagraph = styled(Box)<BoxProps>(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "Montserrat-SemiBold",
  marginTop: "10px",
  marginBottom: "20px",
  [theme.breakpoints.down(440)]: {
    marginTop: vwMobile(10),
    marginBottom: vwMobile(20),
  },
}));

export const BodyParagraph = styled(Box)<BoxProps>(({ theme }) => ({
  lineHeight: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const CustomButtonFooter = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    marginRight: "20px",
    marginTop: "20px",
    "&:hover": {
      cursor: "pointer",
      transition: "all 0.3s",
    },
    [theme.breakpoints.down(440)]: {
      fontSize: vwMobile(15),
      marginRight: vwMobile(20),
      marginTop: vwMobile(15),
    },
    "&:first-child": {
      marginTop: 0,
    },
  })
);

export const PaperBank = styled(Paper)<PaperProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.25rem",
  backgroundColor: "white",
  boxShadow: "none",
}));

export const ImagePembayaran = styled("img")(({ theme }) => ({
  maxHeight: "20px",
  maxWidth: "90px",
  [theme.breakpoints.down(440)]: {
    maxHeight: vwMobile(20),
    maxWidth: vwMobile(65),
  },
}));

export const ImageBantuan = styled("img")(({ theme }) => ({
  maxHeight: "18px",
  maxWidth: "18px",
  marginRight: "8px",
  [theme.breakpoints.down(440)]: {
    maxHeight: vwMobile(18),
    maxWidth: vwMobile(18),
    marginRight: vwMobile(8),
  },
}));

export const CopyrightContainer = styled(Paper)<PaperProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.secondary,
  padding: "0.4rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  fontFamily: "Montserrat-Medium",
  boxShadow: "none",
}));

import {
  BodyParagraph,
  CopyrightContainer,
  CustomButtonFooter,
  FooterContainer,
  ImageBantuan,
  ImageCustom,
  ImagePembayaran,
  PaperBank,
  TitleParagraph,
} from "./Footer.styles";
import ContentPasteSearch from "@mui/icons-material/ContentPasteSearch";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CenteredBox, LeftBox } from "styles";
import { Link, useLocation } from "react-router-dom";
import { menuUrl } from "constants/";
import { useMediaQuery } from "react-responsive";
import { CustomButton } from "components";

function Footer() {
  const isMobile = useMediaQuery({ maxWidth: 440 });

  const { pathname } = useLocation();

  const handleClickYoutube = () => {
    window.open("https://www.youtube.com/@pamanyin", "_blank");
  };

  const handleClickTiktok = () => {
    window.open("https://www.tiktok.com/@paman.yin", "_blank");
  };

  const handleClickWhatsapp = () => {
    window.open("https://wa.me/+62881081917409", "_blank");
  };

  const handleClickTelegram = () => {
    window.open("https://t.me/digipopo", "_blank");
  };

  return (
    <>
      <FooterContainer>
        <Grid container columnSpacing={6} rowSpacing={4}>
          <Grid item xs={12} sm={5} md={6} lg={4}>
            <CenteredBox
              sx={{
                zIndex: 2,
                justifyContent: isMobile ? "center" : "flex-start",
              }}
            >
              <ImageCustom
                src={
                  process.env.PUBLIC_URL +
                  "/assets/icons/logo-paman-yin-primary.png"
                }
                alt="paman yin"
              />
            </CenteredBox>
            <Box sx={{ marginTop: "2rem", zIndex: 2 }}>
              Website top-up terlengkap, tercepat, termurah, dan terpercaya di
              Indonesia. Hanya dalam waktu 1 detik kredit permainan akan
              ditambahkan ke dalam akunmu secara instan. Top up Mobile Legends,
              Free Fire, Ragnarok M, dan berbagai game lainnya segera!
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={8}>
            <Grid container columnSpacing={isMobile ? 3 : 6} rowSpacing={4}>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Menu kami</TitleParagraph>
                <BodyParagraph>
                  <CustomButtonFooter>
                    <Link to={menuUrl.base} style={{ textDecoration: "none" }}>
                      <LeftBox
                        sx={{
                          color:
                            pathname === menuUrl.base
                              ? "primary.main"
                              : "text.primary",
                        }}
                      >
                        <HomeIcon
                          sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                        />
                        Home
                      </LeftBox>
                    </Link>
                  </CustomButtonFooter>
                  <CustomButtonFooter>
                    <Link
                      to={menuUrl.riwayatPesanan}
                      style={{ textDecoration: "none" }}
                    >
                      <LeftBox
                        sx={{
                          color:
                            pathname === menuUrl.riwayatPesanan
                              ? "primary.main"
                              : "text.primary",
                        }}
                      >
                        <ReceiptLongIcon
                          sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                        />
                        Riwayat Transaksi
                      </LeftBox>
                    </Link>
                  </CustomButtonFooter>
                  <CustomButtonFooter>
                    <Link
                      to={menuUrl.cekPesanan}
                      style={{ textDecoration: "none" }}
                    >
                      <LeftBox
                        sx={{
                          color: pathname.includes("/pesanan")
                            ? "primary.main"
                            : "text.primary",
                        }}
                      >
                        <ContentPasteSearch
                          sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                        />
                        Cek Pesanan
                      </LeftBox>
                    </Link>
                  </CustomButtonFooter>
                </BodyParagraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Ikuti akun kami</TitleParagraph>
                <BodyParagraph>
                  <CustomButtonFooter onClick={handleClickTiktok}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/logo-tiktok.png"
                      }
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "0.25rem",
                      }}
                    />
                    TikTok
                  </CustomButtonFooter>
                  <CustomButtonFooter onClick={handleClickYoutube}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-youtube.png"
                      }
                    />
                    YouTube
                  </CustomButtonFooter>
                </BodyParagraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Saluran pembayaran</TitleParagraph>
                <Grid container columnSpacing={1.5} rowSpacing={2}>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bca.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bni.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bri.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/mandiri.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bsi.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/permata-bank.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/qris.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/ovo.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/dana.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/gopay.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/linkaja.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/shopeepay.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Butuh bantuan?</TitleParagraph>
                <BodyParagraph>
                  <CustomButton
                    buttonProps={{
                      onClick: handleClickWhatsapp,
                      sx: { width: "100%", marginBottom: "1rem" },
                    }}
                  >
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-whatsapp.png"
                      }
                    />
                    WhatsApp
                  </CustomButton>
                  <CustomButton
                    buttonProps={{
                      onClick: handleClickTelegram,
                      sx: { width: "100%" },
                    }}
                  >
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-telegram.png"
                      }
                    />
                    Telegram
                  </CustomButton>
                </BodyParagraph>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
      <CopyrightContainer>
        2024 Paman Yin Store &copy; All Rights Reserved.
      </CopyrightContainer>
    </>
  );
}

export default Footer;

import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";

import { defaultBorderRadius, defaultFontSize } from "constants/";
import { rem } from "theme/muiSizes";
import { CustomTextFieldProps } from "types";

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "isError",
})<CustomTextFieldProps>(({ isError, theme }) => ({
  minHeight: rem(50),
  ".MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: theme.palette.text.disabled,
  },
  "&.MuiTextField-root": {
    width: "100%",
    borderRadius: defaultBorderRadius,
  },
  ".MuiInputLabel-root": {
    fontSize: defaultFontSize,
    top: "-0.25rem",
    color: theme.palette.text.secondary,
    "&.MuiInputLabel-shrink": {
      top: "0.05rem",
    },
    "&.Mui-disabled": {
      color: theme.palette.text.disabled,
    },
    "&.Mui-focused": {
      color: theme.palette.text.secondary,
    },
  },
  ".MuiFilledInput-root": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: defaultBorderRadius,
    "&.Mui-focused": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    },
  },
  ".MuiFilledInput-input": {
    fontSize: defaultFontSize,
    fontFamily: "Montserrat-Regular",
    height: "2.5rem",
    color: theme.palette.text.secondary,
    "&::placeholder": {
      color: alpha(theme.palette.text.secondary, 0.8),
      fontSize: defaultFontSize,
    },
  },
  " input": {
    "-webkit-tap-highlight-color": "none !important",
    "::-webkit-outer-spin-button": {
      display: "none",
      WebkitAppearance: "none",
      margin: 0,
    },
    "::-webkit-inner-spin-button": {
      display: "none",
      WebkitAppearance: "none",
      margin: 0,
    },
    ":-webkit-autofill": {
      transition:
        "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
      transitionDelay: "background-color 5000s, color 5000s",
      "&:hover": {
        transition:
          "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
        transitionDelay: "background-color 5000s, color 5000s",
      },
      "&:focus": {
        transition:
          "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
        transitionDelay: "background-color 5000s, color 5000s",
      },
      "&:active ": {
        transition:
          "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
        transitionDelay: "background-color 5000s, color 5000s",
      },
    },
  },
}));
